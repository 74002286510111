<template>
    <div class="row">
      <div class="col-md-12">
        <dashboard-box :back="true" back-component-name="management.invoice_custom_approval_type.index">
          <template v-slot:title>
            <span>{{ $t(id != null ? "invoiceCustomApprovalTypes.edit_invoice_custom_approval_type" : "invoiceCustomApprovalTypes.create_invoice_custom_approval_types") }}</span>
          </template>
          <template v-slot:preview>
            <div class="row d-flex" v-if="expenseProxy">
              <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12"
                   v-for="(value, index) in expenseProxy.translations" :key="index">
                <text-input
                    :key="index"
                    :title="`${$t('custom_invoice_approval_types.name_custom_invoice_approval_type')}(${getLanguageById(value.language_id).name})`"
                    :model.sync="value.name"
                    :placeholder="`${$t('custom_invoice_approval_types.please_custom_invoice_approval_type')}(${getLanguageById(value.language_id).name})`"
                    :is-valid="value != null && value.name.length !== 0 ? true : null"
                ></text-input>
              </div>
  
              <div class="col-xl-4">
                <div class="d-flex items-end justify-content-start">
                  <div>
                    <custom-multi-select
                        :title="$t('expense.expense')"
                        :model.sync="instructionable_id"
                        :options="expenseItems"
                        name="expense"
                        :not-list="true"
                        :is-inline="true"
                        :max="1"
                        :required="true"
                        :item-per-row="1"
                        input-width="auto"
                        input-max-width="200px"
                    ></custom-multi-select>
                  </div>
                </div>
              </div>
              
            </div>
  
            <div class="d-flex justify-content-center mt-5">
              <button-with-icon
                  class="mr-3 danger-border-1px"
                  @onClick="$router.go(-1)"
                  :text="$t('general.cancel')"
                  size="lg"
                  :icon-name="null"
              ></button-with-icon>
              <button-with-icon
                  @onClick="createOrUpdateExpense"
                  :text="$t(id != null ? 'general.save' : 'general.create')"
                  size="lg"
                  :icon-name="null"
                  :disabled="!isValidToCreate"
              ></button-with-icon>
            </div>
          </template>
        </dashboard-box>
      </div>
    </div>
  </template>
  
  <script>
  import DashboardBox from "@/assets/components/DashboardBox";
  import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
  import {mapMutations, mapGetters} from 'vuex'
  import {
    CREATE_ITEM,
    CURRENT_ITEM,
    ERROR, GET_ITEMS,
    ITEMS,
    GET_ITEM_DETAIL_BY_ID,
    LOADING, SET_CURRENT_ITEM, SUCCESS, UPDATE_ITEM_BY_ID
  } from "@/core/services/store/panel-managment/crud/panel.management.module";
  import TextInput from "@/assets/components/inputs/TextInput";
  import SelectInput from "@/assets/components/inputs/SelectInput";
  import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";
  import {LANGUAGE} from "@/core/services/store/system-options.module";
  import ButtonWithIcon from "@/assets/components/widget/ButtonWithIcon";
  
  export default {
    name: "InvoiceCustomApprovalTypeCreateEdit",
    components: {
      ButtonWithIcon,
      TextInput,
      SelectInput,
      CustomMultiSelect,
      DashboardBox
    },
    computed: {
      ...mapGetters({
        loading: LOADING,
        error: ERROR,
        success: SUCCESS,
        expense: CURRENT_ITEM,
        language: LANGUAGE,
      }),
      isValidToCreate() {
        if (this.expense.translations == null || this.instructionable_id == null|| this.expense.translations.length === 0) return false;
  
        for (let i = 0; i < this.expense.translations.length; i++) {
          if (this.expense.translations[i].name == null || this.expense.translations[i].name.length === 0) {
            return false;
          }
        }
  
        return true;
      },
      expenseProxy: {
        get() {
          if (this.id != null && this.expense) {
            this.type_id = this.expense.type_id;
            this.instructionable_id = this.expense.instructionable_id;
            this.expenseItems = this.expense.cache_items;
          }
  
          return this.expense;
        },
        set(value) {
          this.setStasuses(value);
        }
      }
    },
    data() {
      return {
        id: null,
        languages: [],
        expenseItems: [],
        instructionable_id: this.id ? this.expenseProxy.instructionable_id : null,
        type_id: this.id ? this.expenseProxy.type_id : null,
        tempItem: {
          translations: []
        },
      };
    },
    methods: {
      ...mapMutations({
        setStasuses: SET_CURRENT_ITEM,
      }),
      getCacheData () {
        let payload = {
          url: 'api/expenses',
        }
  
        this.$store.dispatch(GET_ITEMS, payload).then(response => {
          if (response.status) {
            this.expenseItems = this.convertArrayToObjectByKey(response.data, 'id', 'translations.0.name')
          }
        })
      },
      createOrUpdateExpense() {
        let contents = [];
        this.expenseProxy.translations.forEach(item => {
          contents.push(item)
        })
  
        let payload = {
          url: this.id == null ? 'api/invoice-custom-approval-types' : 'api/invoice-custom-approval-types/' + this.id,
          id: this.id,
          contents: {
            translations: this.expenseProxy.translations,
            type_id: this.type_id,
            instructionable_id: this.instructionable_id,
          },
          redirectRouteName: 'management.invoice_custom_approval_type.index',
          successMessage: this.$t(this.id == null ? 'general.successfully_created' : 'general.successfully_updated'),
        }
        this.$store.dispatch(this.id == null ? CREATE_ITEM : UPDATE_ITEM_BY_ID, payload);
      },
    },
    mounted() {
      if(!this.isUserGranted('InvoiceCustomApprovalType', ['update','create'],true,true)){
        return false;
      }
      this.id = this.$route.params.id;
  
      if (this.id != null) {
        let payload = {
          url: 'api/invoice-custom-approval-types/' + this.id,
          id: this.id,
          redirectRouteOnNotFound: 'management.invoice_custom_approval_types.index'
        }
  
        this.$store.dispatch(GET_ITEM_DETAIL_BY_ID, payload).then(response=>{
          this.instructionable_id=response.data.instructionable_id
        })
      }
  
      this.getCacheData();
  
      this.$store.dispatch(SET_BREADCRUMB, [
        {title: this.$t("general.dashboard"), route: "dashboard"},
        {title: this.$t("menu.management.invoice_custom_approval_types"), route: "management.invoice_custom_approval_type.index"},
        {title: this.$t(this.id != null ? "custom_invoice_approval_types.edit_custom_invoice_approval_type" : "custom_invoice_approval_types.create_custom_invoice_approval_type")},
      ]);
  
      this.languages = this.convertArrayToObjectByKey((this.systemLanguages), 'id', 'name');
  
      if(!this.id){
        this.$store.dispatch(SET_CURRENT_ITEM,null)
        let languages = this.systemLanguages;
        let self = this;
  
        languages.forEach(item => {
          self.tempItem.translations.push({
            language_id: item.id,
            name: "",
          })
        })
        this.setStasuses(self.tempItem)
      }
    }
  }
  </script>
  
  <style scoped>
  #dashboard-box {
    padding: 10px;
  }
  </style>
  